import React from "react";
import { RichTextField } from "admin-on-rest/lib/mui/field";

import { List, Datagrid, TextField, ImageField, EditButton } from "react-admin";

const FooterList = (props) => {
    return (
        <List {...props} pagination={false} title="Политика">
            <Datagrid>
                <EditButton />

                <TextField
                    label="Заголовок Мероприятий"
                    source="timetableTitle"
                    sortable={false}
                />

                <TextField
                    label="Заголовок Курсов"
                    source="courseTitle"
                    sortable={false}
                />

                <TextField
                    label="Заголовок Личного кабиннета"
                    source="cabinetTitle"
                    sortable={false}
                />
            </Datagrid>
        </List>
    );
};

export default FooterList;
